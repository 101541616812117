import EventsTracker from "./fb";

declare const window: {
  dataLayer: Record<string, unknown>[];
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const gtag = (event: string | null, args?: Record<string, unknown>) => {
  if (window?.dataLayer) {
    const payload = {
      event: event || undefined,
      ...(args || {})
    };
    window.dataLayer.push(payload);
  }
};

export const gtagSetUser = (data: {
  id?: number;
  email: string;
  firstName: string;
  lastName: string;
}) => {
  const payload = {
    user_id: data.id ? EventsTracker.hash(`${data.id}`) : undefined,
    email: EventsTracker.hash(data.email),
    first_name: EventsTracker.hash(data.firstName),
    last_name: EventsTracker.hash(data.lastName)
  };
  gtag("set_user_data", payload);
};
