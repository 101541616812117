import React, { useState, useEffect, useCallback } from "react";
import { maybeAuthorised } from "../request";
import { Comparison } from "../types/Comparison";
import { useUserState } from "./UserContext";
import { ComparisonItem } from "../types/items/ComparisonItem";

export const COMPARISON_TOKEN = "DD_COMPARISON_TOKEN";

const ComparisonContext = React.createContext<{
  opened: boolean;
  comparison: Comparison;
  fetchComparison: () => void;
  openComparison: () => void;
  closeComparison: () => void;
  isInComparison: (productId: number) => ComparisonItem | undefined;
}>({
  opened: false,
  comparison: {
    uuid: "",
    items: []
  },
  fetchComparison: () => {},
  openComparison: () => {},
  closeComparison: () => {},
  isInComparison: () => {
    return undefined;
  }
});

export function ComparisonProvider(props: { children: React.ReactNode }) {
  const { isAuthenticated } = useUserState();
  const [opened, setOpened] = useState(false);
  const [comparison, setComparison] = useState<Comparison>({
    uuid: "",
    items: []
  });

  const fetchComparison = useCallback(async () => {
    try {
      const token = localStorage.getItem(COMPARISON_TOKEN);
      const API = maybeAuthorised();
      const response = await API.request<Comparison>({
        method: "GET",
        url: `/api/frontend/comparison/${token ? `?token=${token}` : ""}`
      });

      localStorage.setItem(COMPARISON_TOKEN, response.data.uuid);
      setComparison(response.data);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const openComparison = () => {
    setOpened(true);
  };

  const closeComparison = () => {
    setOpened(false);
  };

  const isInComparison = (productId: number) => {
    return comparison.items.find(i => i.product.id === productId);
  };

  useEffect(() => {
    fetchComparison();
  }, [isAuthenticated]);

  return (
    <ComparisonContext.Provider
      value={{
        opened,
        openComparison,
        closeComparison,
        comparison,
        fetchComparison,
        isInComparison
      }}
    >
      {props.children}
    </ComparisonContext.Provider>
  );
}

export function useComparison() {
  const context = React.useContext(ComparisonContext);

  if (context === undefined) {
    throw new Error("useComparison must be used within a ComparisonProvider");
  }

  return context;
}
