import CryptoJS from "crypto-js";

type ActionType = "trackCustom" | "track";

type Event = {
  type: ActionType;
  name: string;
  args?: Record<string, unknown>;
};

declare const window: {
  fbq?: (...args: any[]) => void;
};

type StandardEvent =
  | "ViewContent"
  | "AddPaymentInfo"
  | "AddToCart"
  | "AddToWishlist"
  | "CompleteRegistration"
  | "CustomizeProduct"
  | "InitiateCheckout"
  | "PageView"
  | "Purchase";

export const FB_PIXEL_ID = "351006012818732";

class FbEvents {
  queue: Array<Event> = [];
  fb: (...args: any[]) => void = () => {};

  constructor() {
    this.initialize();
  }

  initialize() {
    if (process.browser && window.fbq) {
      this.fb = window.fbq;
      this.runQueuedEvents();
    } else {
      this.checkForScriptLoaded();
    }
  }

  checkForScriptLoaded() {
    if (process.browser && window.fbq) {
      this.runQueuedEvents();
    } else {
      setTimeout(() => {
        this.initialize();
      }, 1000);
    }
  }

  runQueuedEvents = () => {
    this.queue.forEach((event) => {
      this.fb(event.type, event.name, { ...event.args });
    });
  };

  trackCustom = (name: string, args?: Record<string, unknown>) => {
    if (this.fb) {
      this.fb("trackCustom", name, { ...args });
    } else {
      this.queue.push({
        type: "trackCustom",
        name,
        args
      });
    }
  };

  setUser = (data: {
    id?: number;
    email: string;
    firstName: string;
    lastName: string;
  }) => {
    const payload = {
      external_id: data.id ? this.hash(`${data.id}`) : undefined,
      em: this.hash(data.email),
      fn: this.hash(data.firstName),
      ln: this.hash(data.lastName)
    };
    this.fb("init", FB_PIXEL_ID, payload);
    this.trackEvent("PageView");
  };

  trackEvent = (eventName: StandardEvent, args?: Record<string, unknown>) => {
    if (this.fb) {
      this.fb("track", eventName, { ...args });
    } else {
      this.queue.push({ type: "track", name: eventName, args });
    }
  };

  hash = (v: string) =>
    CryptoJS.SHA256(v.trim().toLocaleLowerCase()).toString(CryptoJS.enc.Hex);
}

const EventsTracker = new FbEvents();

export default EventsTracker;
