import axios from "axios";
import { UserProfile } from "./types/UserProfile";
import { RECENTLY_VIEWED_TOKEN } from "./context/RecentlyViewedContext";
import { COMPARISON_TOKEN } from "./context/ComparisonContext";
import { INTERIOR_DESIGNER_TOKEN } from "./context/InteriorDesignerContext";

export type LoginValues = { email: string; password: string };
export type RegistrationValues = {
  email: string;
  password: string;
  password2: string;
  first_name: string;
  last_name: string;
  how_did_you_find: string;
  how_did_you_find_value: string;
  tos: boolean;
};

const ACCESS_TOKEN_KEY = "DD_access_token";
const REFRESH_TOKEN_KEY = "DD_refresh_token";

const config = {
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};

export const notAuthorised = axios.create(config);

export async function refreshToken() {
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);

  if (!refreshToken) {
    throw new Error("Refresh token doesn't exist");
  }

  const response = await notAuthorised.request({
    method: "POST",
    url: "/api/refresh/",
    data: {
      refresh: refreshToken,
    },
  });

  localStorage.setItem(ACCESS_TOKEN_KEY, response.data.access);
}

export async function login(values: { email: string; password: string }) {
  const response = await notAuthorised.request<{
    access: string;
    refresh: string;
  }>({
    method: "POST",
    url: "/api/login/",
    data: {
      username: values.email,
      password: values.password,
    },
  });

  localStorage.setItem(ACCESS_TOKEN_KEY, response.data.access);
  localStorage.setItem(REFRESH_TOKEN_KEY, response.data.refresh);
}

export function logout() {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
  localStorage.removeItem(RECENTLY_VIEWED_TOKEN);
  localStorage.removeItem(COMPARISON_TOKEN);
  localStorage.removeItem(INTERIOR_DESIGNER_TOKEN);
}

export async function register(values: RegistrationValues) {
  const response = await notAuthorised.request<{
    userprofile: UserProfile;
    access: string;
    refresh: string;
  }>({
    method: "POST",
    url: "/api/frontend/registration/",
    data: {
      username: values.email,
      password: values.password,
      password2: values.password2,
      first_name: values.first_name,
      last_name: values.last_name,
      how_did_you_find: values.how_did_you_find,
      how_did_you_find_value: values.how_did_you_find_value,
      tos: values.tos,
    },
  });

  localStorage.setItem(ACCESS_TOKEN_KEY, response.data.access);
  localStorage.setItem(REFRESH_TOKEN_KEY, response.data.refresh);

  return {
    userprofile: response.data.userprofile,
  };
}

export async function resetPassword(values: { email: string }) {
  await notAuthorised.request({
    method: "POST",
    url: "/api/frontend/reset-password/",
    data: {
      email: values.email,
    },
  });
}

export function authorised() {
  const token = localStorage.getItem(ACCESS_TOKEN_KEY);

  if (!token) {
    throw new Error("Not Authorized");
  }

  return axios.create({
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    },
  });
}

export function maybeAuthorised() {
  const token = localStorage.getItem(ACCESS_TOKEN_KEY);

  return axios.create({
    ...config,
    headers: {
      ...config.headers,
      Authorization: token ? `Bearer ${token}` : undefined,
    },
  });
}

export async function getUserData() {
  const API = authorised();

  return await API.request<UserProfile>({
    method: "GET",
    url: `/api/frontend/my-profile/`,
  });
}
