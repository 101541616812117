import React, { useState, useEffect, useCallback } from "react";
import { maybeAuthorised } from "../request";
import { InteriorDesigner } from "../types/InteriorDesigner";
import { useUserState } from "./UserContext";
import { InteriorDesignerItem } from "../types/items/InteriorDesignerItem";

export const INTERIOR_DESIGNER_TOKEN = "DD_INTERIOR_DESIGNER_TOKEN";

const defaultValue = {
  uuid: "",
  image: "",
  items: [],
  wall_x: null,
  wall_y: null,
  wall_width: null,
  wall_height: null,
  wall_scale_ratio: null,
};
const InteriorDesignerContext = React.createContext<{
  opened: boolean;
  interiorDesigner: InteriorDesigner;
  fetchInteriorDesigner: () => void;
  openInteriorDesigner: () => void;
  closeInteriorDesigner: () => void;
  isInInteriorDesigner: (productId: number) => InteriorDesignerItem | undefined;
}>({
  opened: false,
  interiorDesigner: defaultValue,
  fetchInteriorDesigner: () => {},
  openInteriorDesigner: () => {},
  closeInteriorDesigner: () => {},
  isInInteriorDesigner: () => {
    return undefined;
  },
});

export function InteriorDesignerProvider(props: { children: React.ReactNode }) {
  const { isAuthenticated } = useUserState();
  const [opened, setOpened] = useState(false);
  const [interiorDesigner, setInteriorDesigner] =
    useState<InteriorDesigner>(defaultValue);

  const fetchInteriorDesigner = useCallback(async () => {
    try {
      const token = localStorage.getItem(INTERIOR_DESIGNER_TOKEN);
      const API = maybeAuthorised();
      const response = await API.request<InteriorDesigner>({
        method: "GET",
        url: `/api/frontend/interior-designer/${
          token ? `?token=${token}` : ""
        }`,
      });

      localStorage.setItem(INTERIOR_DESIGNER_TOKEN, response.data.uuid);
      setInteriorDesigner(response.data);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const openInteriorDesigner = () => {
    setOpened(true);
  };

  const closeInteriorDesigner = () => {
    setOpened(false);
  };

  const isInInteriorDesigner = (productId: number) => {
    return interiorDesigner.items.find((i) => i.product.id === productId);
  };

  useEffect(() => {
    fetchInteriorDesigner();
  }, [isAuthenticated]);

  return (
    <InteriorDesignerContext.Provider
      value={{
        opened,
        openInteriorDesigner,
        closeInteriorDesigner,
        interiorDesigner,
        fetchInteriorDesigner,
        isInInteriorDesigner,
      }}
    >
      {props.children}
    </InteriorDesignerContext.Provider>
  );
}

export function useInteriorDesigner() {
  const context = React.useContext(InteriorDesignerContext);

  if (context === undefined) {
    throw new Error(
      "useInteriorDesigner must be used within a InteriorDesignerProvider"
    );
  }

  return context;
}
