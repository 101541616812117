import React, { useState, useEffect, useCallback } from "react";
import { maybeAuthorised } from "../request";
import { RecentlyViewed } from "../types/RecentlyViewed";
import { useUserState } from "./UserContext";

export const RECENTLY_VIEWED_TOKEN = "DD_RECENTLY_VIEWED_TOKEN";

const RecentlyViewedContext = React.createContext<{
  recentlyViewed: RecentlyViewed;
  fetchRecentlyViewed: () => void;
}>({
  recentlyViewed: {
    uuid: "",
    items: []
  },
  fetchRecentlyViewed: () => {}
});

export function RecentlyViewedProvider(props: { children: React.ReactNode }) {
  const { isAuthenticated } = useUserState();
  const [recentlyViewed, setRecentlyViewed] = useState<RecentlyViewed>({
    uuid: "",
    items: []
  });

  const fetchRecentlyViewed = useCallback(async () => {
    try {
      const token = localStorage.getItem(RECENTLY_VIEWED_TOKEN);
      const API = maybeAuthorised();
      const response = await API.request<RecentlyViewed>({
        method: "GET",
        url: `/api/frontend/recently-viewed/${token ? `?token=${token}` : ""}`
      });

      localStorage.setItem(RECENTLY_VIEWED_TOKEN, response.data.uuid);
      setRecentlyViewed(response.data);
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    fetchRecentlyViewed();
  }, [isAuthenticated]);

  return (
    <RecentlyViewedContext.Provider
      value={{
        recentlyViewed,
        fetchRecentlyViewed
      }}
    >
      {props.children}
    </RecentlyViewedContext.Provider>
  );
}

export function useRecentlyViewed() {
  const context = React.useContext(RecentlyViewedContext);

  if (context === undefined) {
    throw new Error(
      "useRecentlyViewed must be used within a RecentlyViewedProvider"
    );
  }

  return context;
}
