import React, { useState, useEffect } from "react";
import {
  refreshToken,
  login,
  logout,
  getUserData,
  LoginValues,
  RegistrationValues,
  register,
} from "../request";
import { UserProfile } from "../types/UserProfile";
import { mixpanelIdentify, mixpanelEvent } from "../helpers/analytics";
import EventsTracker from "../helpers/fb";
import { gtagSetUser, gtag } from "../helpers/gtag";

type State = {
  isAuthenticated: boolean | null;
  userprofile: UserProfile | null;
};

const emptyState = {
  isAuthenticated: null,
  userprofile: null,
};

type Context = State & {
  login?: (values: LoginValues) => Promise<void>;
  register?: (values: RegistrationValues) => Promise<void>;
  logout?: () => void;
};

const UserStateContext = React.createContext<Context>(emptyState);

export function UserProvider({ children }: { children: React.ReactNode }) {
  const [state, setState] = useState<State>(emptyState);

  useEffect(() => {
    const checkToken = async () => {
      try {
        await refreshToken();

        setState((state) => ({
          ...state,
          isAuthenticated: true,
        }));
      } catch {
        setState((state) => ({
          ...state,
          isAuthenticated: false,
        }));
      }
    };

    checkToken();
  }, []);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await getUserData();
        const userprofile = response.data;

        setState((state) => ({
          ...state,
          userprofile,
          isAuthenticated: true,
        }));

        const userAnalytics = {
          id: userprofile.user.id,
          email: userprofile.user.username,
          firstName: userprofile.user.first_name,
          lastName: userprofile.user.last_name,
        };

        // EventsTracker.setUser(userAnalytics);
        gtagSetUser(userAnalytics);

        mixpanelIdentify(userprofile);
      } catch {
        setState((state) => ({
          ...state,
          userprofile: null,
          isAuthenticated: false,
        }));
      }
    };

    if (state.isAuthenticated) {
      fetchProfile();
    } else {
      EventsTracker.trackEvent("PageView");
    }
  }, [state.isAuthenticated]);

  const actions = {
    login: async (values: LoginValues) => {
      try {
        await login(values);

        const response = await getUserData();
        const userprofile = response.data;

        setState((state) => ({
          ...state,
          userprofile,
          isAuthenticated: true,
        }));

        mixpanelIdentify(userprofile);
      } catch (e) {
        setState((state) => ({
          ...state,
          userprofile: null,
          isAuthenticated: false,
        }));

        throw new Error(e.response.data.detail || "Something went wrong");
      }
    },
    register: async (values: RegistrationValues) => {
      const { userprofile } = await register(values);

      setState((state) => ({
        ...state,
        userprofile,
        isAuthenticated: true,
      }));

      EventsTracker.trackEvent("CompleteRegistration");
      gtag("CompleteRegistration");

      mixpanelIdentify(userprofile);
      mixpanelEvent("Registration");
    },
    logout: async () => {
      logout();
      setState({
        isAuthenticated: false,
        userprofile: null,
      });
    },
  };

  return (
    <UserStateContext.Provider value={{ ...state, ...actions }}>
      {children}
    </UserStateContext.Provider>
  );
}

export function useUserState() {
  const context = React.useContext(UserStateContext);

  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }

  return context;
}
