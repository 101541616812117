import { UserProfile } from "../types/UserProfile";
import capitalize from "lodash/capitalize";
import mixpanel from "mixpanel-browser";

declare global {
  interface Window {
    mixpanel: any;
    ga: any;
    hj: any;
  }
}

export function mixpanelEvent(event: string, options?: any) {
  mixpanel.track(event, { ...options, send_immediately: true });
}

export function mixpanelIdentify(userprofile: UserProfile) {
  mixpanel.identify(`${userprofile.user.id}`);
  mixpanel.people.set({
    $first_name: capitalize(userprofile.user.first_name),
    $last_name: capitalize(userprofile.user.last_name),
    $email: userprofile.user.username,
  });
}

export function hotjarTrigger(name: string) {
  if (typeof window.hj === "undefined") return;

  window.hj("trigger", name);
}

export function hotjarVPV(url: string) {
  if (typeof window.hj === "undefined") return;

  window.hj("vpv", url);
}

export function gaEvent(options: {
  eventCategory: string;
  eventAction: string;
  eventLabel?: string;
  transport?: "beacon";
  eventValue?: number;
}) {
  if (typeof window.ga === "undefined") return;

  window.ga("send", "event", options);
}

export function gaSet(pageUrl: string) {
  if (typeof window.ga === "undefined") return;

  window.ga("set", "page", pageUrl);
}

export function gaPageview() {
  if (typeof window.ga === "undefined") return;

  window.ga("send", "pageview");
}

export const eventId = () => {
  const timestamp = Date.now().toString(36);
  const randomComponent = Math.random().toString(36).slice(2, 11);
  return `${timestamp}-${randomComponent}`;
};
